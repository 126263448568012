import {
  makeResponsive,
  placeTitle,
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  placeGravity,
  placeText,
  placeMiddleText,
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeCircle,
  placeAngle,
  placeDash,
  placeLabel,
  placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
  placeArc,
  placeLogo,
  placeErase,
  placeShuffle,
  placeTest,
  placeSeg,
  setConfig,
  placeStat,
  placeTri,
  shuffle,
  placeEraseLeft,
  placeEraseRight,
  createSpace,
  placeStartOver,
  writeHTMLText,
  placeBoldText,
  drawPoint,
  setSize,
  drawSlider,
  placePlay,
  placeRedo,
  drawLine,
  drawSegment,
  placeUndo
} from '../Utils';
const Boxes = {
  box1: function () {
    var brd1 = createSpace(-6, 6, -4, 8);
    brd1.options.board.minimizeReflow = 'none';
    brd1.options.point.showInfobox =false;
    brd1.options.line.highlight=false;
    brd1.options.image.highlight=false;
    brd1.options.text.highlight=false;
    brd1.options.polygon.highlight=false;
    makeResponsive(brd1);
    placeLogo(brd1);
    placeTitle(brd1, 'Sum of Exterior Angles in a Quadrilateral', '(Shuffle to generate a different quadrilateral)');
  //  var XList=[];
  //  var YList=[];
    var i=0; var j=0; var im=0; var jm=0; var km=0; var lm=0;
    var Pt1 = brd1.create('point', [-2,2],{name:'A', size:4, fillColor:'white', strokeColor:'black', snapToGrid:false, label: {offset:[-15, 15], fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    //
    var Pt2 = brd1.create('point', [2,3],{name:'B ', size:4, fillColor:'white',strokeColor:'black', snapToGrid:false, label: {offset:[15, 15],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    //
    var Pt3 = brd1.create('point', [1,-1],{name:'C', size:4,fillColor:'white',  strokeColor:'black', snapToGrid:false, label: {offset:[15, -15],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    //
    var Pt4 = brd1.create('point', [-3,-2],{name:'D', size:4,fillColor:'white',  strokeColor:'black', snapToGrid:false,label: {offset:[-15, -15],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    /////////////Point#1///////////////
    Pt1.on('down', function(){i=1});
    Pt1.on('up', function(){i=0});
    /////////////Point#2///////////////
    Pt2.on('down', function(){i=1});
    Pt2.on('up', function(){i=0});
    /////////////Point#3///////////////
    Pt3.on('down', function(){i=1});
    Pt3.on('up', function(){i=0});
    /////////////Point#4///////////////
    Pt4.on('down', function(){i=1});
    Pt4.on('up', function(){i=0});
    //brd1.create('segment', [Pt1, Pt3], {strokeColor:'blue', strokeWidth:1, fixed:true});
    var b0=drawSegment(brd1, Pt1, Pt2);
    b0.setAttribute({strokeColor:function(){if(im>=1){return 'red'}else{return 'black'}}});
    var b1=drawSegment(brd1, Pt2, Pt3);
    b1.setAttribute({strokeColor:function(){if(im>=2){return 'red'}else{return 'black'}}});
    var b2=drawSegment(brd1, Pt3, Pt4);
    b2.setAttribute({strokeColor:function(){if(im>=3){return 'red'}else{return 'black'}}});
    var b3=drawSegment(brd1, Pt4, Pt1);
    b3.setAttribute({strokeColor:function(){if(im>=4){return 'red'}else{return 'black'}}});
    var ln0 =drawLine(brd1, Pt4, Pt1);
    var ln1 =drawLine(brd1, Pt1, Pt2);
    var ln2 =drawLine(brd1, Pt2, Pt3);
    var ln3 =drawLine(brd1, Pt3, Pt4);
    ln0.setAttribute({straightFirst:false, visible:()=>im>=1, dash:1});
    ln1.setAttribute({straightFirst:false, visible:()=>im>=2, dash:1});
    ln2.setAttribute({straightFirst:false, visible:()=>im>=3, dash:1});
    ln3.setAttribute({straightFirst:false, visible:()=>im>=4, dash:1});
    var ang1 = brd1.create('nonreflexangle', [b0, ln0, 1, 1],{name:'a', radius:1, strokeColor:'blue', fillColor:'blue',  label:{color:'black', fontSize:function(){return 22*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    ang1.setAttribute({visible:()=>im>=1});
    var ang2 = brd1.create('nonreflexangle', [b1, ln1, 1, 1],{name:'b', radius:1, strokeColor:'blue', fillColor:'blue',  label:{color:'black', fontSize:function(){return 22*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    ang2.setAttribute({visible:()=>im>=2});
    var ang3 = brd1.create('nonreflexangle', [b2, ln2, 1, 1],{name:'c',radius:1, strokeColor:'blue', fillColor:'blue',  label:{color:'black', fontSize:function(){return 22*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    ang3.setAttribute({visible:()=>im>=3});
    var ang4 = brd1.create('nonreflexangle', [b3, ln3, 1, 1],{name:'d', radius:1, strokeColor:'blue', fillColor:'blue',  label:{color:'black', fontSize:function(){return 22*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    ang4.setAttribute({visible:()=>im>=4});
  //  brd1.create('text', [0, 5.5, function(){return '&alpha; = '+ (ang1.Value()*180/Math.PI).toFixed(2) + '^o'}],{visible:()=>im==1, color:'blue', anchorX:'middle', anchorY:'middle', highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/800.)},fixed:true});
//
    brd1.create('text', [-3-1, 5.5, function(){return 'a = '+ (ang1.Value()*180/Math.PI).toFixed(2) + '^o'}],{color:'blue', anchorX:'left', anchorY:'middle', highlight:false,visible:()=>im>=1,  CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}, fixed:true, useASCIIMathML:true});
//
    brd1.create('text', [-1-1, 5.5, function(){return 'b = '+ (ang2.Value()*180/Math.PI).toFixed(2)+ '^o'}],{color:'blue', anchorX:'middle', anchorY:'middle', highlight:false, visible:()=>im>=2, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}, fixed:true,useASCIIMathML:true});
//
    brd1.create('text', [0., 5.5, function(){return 'c = '+ (ang3.Value()*180/Math.PI).toFixed(2) + '^o'}],{color:'blue', anchorX:'middle', anchorY:'middle', highlight:false, visible:()=>im>=3, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)},fixed:true,useASCIIMathML:true});
//
    brd1.create('text', [2., 5.5, function(){return 'd = '+ (ang4.Value()*180/Math.PI).toFixed(2) + '^o'}],{display:'html',color:'blue', anchorX:'middle', anchorY:'middle', highlight:false, visible:()=>im>=4, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)},fixed:true,useASCIIMathML:true});
//
     brd1.create('text', [0, 4.5, function(){return 'Sum = '+ ((ang1.Value()+ang2.Value()+ang3.Value()+ang4.Value())*180/Math.PI).toFixed(0) + '^o'}],{ anchorX:'middle', anchorY:'middle', color:'blue',highlight:false,visible:()=>im==4, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/800.)},fixed:true, useASCIIMathML:true});
//
    brd1.create('text', [0, 4.5, function(){return 'Sum = '+ ((ang1.Value()+ang2.Value())*180/Math.PI).toFixed(2) + '^o'}],{visible:()=>im==2, color:'blue', anchorX:'middle', anchorY:'middle', highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/800.)},fixed:true});

    brd1.create('text', [0, 4.5, function(){return 'Sum = '+ ((ang1.Value()+ang2.Value()+ang3.Value())*180/Math.PI).toFixed(2) + '^o'}],{visible:()=>im==3, color:'blue', anchorX:'middle', anchorY:'middle', highlight:false,useASCIIMathML:true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/800.)},fixed:true});

    var poly = brd1.create('polygon', [Pt1, Pt2, Pt3, Pt4],{fixed:true, strokeWidth:2, fillColor:'white', fillOpacity:1, highlight:false, borders:{fixed:true, strokeColor:'black', strokeWidth:2, highlight:false}});
    var erase=placeErase(brd1, 'right');
    erase.setAttribute({visible:()=>im==4});
    erase.on('down', function(){im=0});
    var glid =drawPoint(brd1,-2, 2);
    var shuffle=placeShuffle(brd1, 'left');
    shuffle.setAttribute({visible:()=>im==0});
    shuffle.on('down', function(){
      let ij=Math.random()*1.5+1;
      glid.moveTo([-2-ij, 2], 100);
      Pt1.moveTo([-2-ij, 2], 100);
      Pt2.moveTo([2, 3], 100);
      Pt3.moveTo([1+ij, -1+ij], 100);
      Pt4.moveTo([-3, -2+ij], 100);
    });
    var prev = placeUndo(brd1,'left');
    prev.setAttribute({visible:()=>im>0});
    prev.on('down', function(){
      if(im==1){
        glid.moveTo([Pt1.X(), Pt1.Y()], 100);
      }
      else if(im==2){
        glid.moveTo([Pt2.X(), Pt2.Y()], 100);
      }
      else if(im==3){
        glid.moveTo([Pt3.X(), Pt3.Y()], 100);
      }
      else if(im==4){
        glid.moveTo([Pt4.X(), Pt4.Y()], 100);
      }
      if(im>0)
      {
      im-=1;
      }
      else {
      im=0;
      }
      });
    var play =placeRedo(brd1);
    play.setAttribute({visible:()=>im<4});
    play.on('down', function(){
      if(im==0){
        glid.moveTo([Pt2.X(), Pt2.Y()], 100);
      }
      else if(im==1){
        glid.moveTo([Pt3.X(), Pt3.Y()], 100);
      }
      else if(im==2){
        glid.moveTo([Pt4.X(), Pt4.Y()], 100);
      }
      else if(im==3){
        glid.moveTo([Pt1.X(), Pt1.Y()], 100);
      }
      if(im<4)
      {
      im+=1;
      }
      else {
      im=4;
      }
      });
    }
}
export default Boxes;
